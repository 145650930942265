import React, { useRef, useEffect } from 'react';
import { DiffOption } from './types';

const SelectDiffsForChallenge = () => {
  const modalRef = useRef<HTMLDialogElement>(null);

  const diffSelectFormElement: HTMLSelectElement | HTMLElement | null = document.getElementById('challenge_diff_ids');

  const [diffs, setDiffs] = React.useState<DiffOption[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('')
  const [selectedDiffs, setSelectedDiffs] = React.useState<Set<number>>(new Set())

  useEffect(() => {
    fetchDiffsFromSelectElement()
  }, [])

  const fetchDiffsFromSelectElement = async () => {
    if (diffSelectFormElement && diffSelectFormElement instanceof HTMLSelectElement) {
      const available = Array.from(diffSelectFormElement.options).map<DiffOption>(option => ({ id: Number(option.value), title: option.innerText }))
      setDiffs(available)

      const selected = Array.from(diffSelectFormElement.selectedOptions).map(option => Number(option.value))
      setSelectedDiffs(new Set(selected))
    }
  }

  const toggleDiffSelection = (diffId: number) => {
    const newSelection = new Set(selectedDiffs);

    if (newSelection.has(diffId)) {
      newSelection.delete(diffId);
    } else {
      newSelection.add(diffId);
    }

    setSelectedDiffs(newSelection);
  };

  const handleConfirm: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()

    if (diffSelectFormElement && diffSelectFormElement instanceof HTMLSelectElement) {
      Array.from(diffSelectFormElement.options).forEach(option => option.selected = false);

      Array.from(selectedDiffs).forEach(diffId => {
        const option = Array.from(diffSelectFormElement.options).find(option => option.value === diffId.toString());
        if (option) {
          option.selected = true;
        }
      });
    }

    hideVisibility(e)
  };

  const filteredDiffs = searchTerm.length > 0
    ? diffs
      .filter(diff => diff.title.toLowerCase().includes(searchTerm.toLowerCase()))
    : diffs;

  const showVisibility: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()

    if (!modalRef.current) return

    modalRef.current.showModal(); // Open the dialog
    modalRef.current.focus();

    await fetchDiffsFromSelectElement()
  }

  const hideVisibility: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (e) e.preventDefault()

    if (!modalRef.current) return

    modalRef.current.close()
  }

  return (
    <div>
      <dialog ref={modalRef} className="modal">
        <h3 className="select-diffs__headline">Select Diffs</h3>
        <div className="select-diffs__search">
          <input
            className="form__input"
            type="text"
            placeholder="Search Diffs..."
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
          />
          {
            searchTerm.length > 0
              ? <div onClick={() => setSearchTerm('')} className="select-diffs__clear-search">
                <span className="material-symbols-outlined">close</span>
              </div>
              : <></>
          }
        </div>

        <ul className="select-diffs__list">
          {filteredDiffs.map((diff) => (
            <li key={diff.id} className={`select-diffs__item ${selectedDiffs.has(diff.id) ? 'selected' : ''}`}
              onClick={() => toggleDiffSelection(diff.id)}>
              <span className="material-symbols-outlined">
                {selectedDiffs.has(diff.id) ? 'check_circle' : 'add_circle'}
              </span>
              {diff.title}
            </li>
          ))}
        </ul>
        <div className="select-diffs__info">
          <p>Selected Diffs: {selectedDiffs.size}</p>
        </div>
        <div className="modal__actions">
          <button onClick={hideVisibility} className='btn btn--outline'>Cancel</button>
          <button onClick={handleConfirm} className='btn btn--primary'>Select</button>
        </div>
      </dialog>

      <p>Selected Diffs</p>

      <ul className="select-diffs__selected">
        {selectedDiffs.size === 0 && <li className="select-diffs__item">No Diffs selected</li>}
        {Array.from(selectedDiffs).map(diffId => (
          <li key={diffId} className="select-diffs__item selected">
            {diffs.find(diff => diff.id === diffId)?.title}
          </li>
        ))}
      </ul>
      <button onClick={showVisibility} className='btn btn--text'>
        <span className="material-symbols-outlined">
          add
        </span>
        Select Diffs for Challenge</button>
    </div>
  )
}

export default SelectDiffsForChallenge;
