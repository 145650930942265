import React from 'react';
import { createRoot } from 'react-dom/client';
import GithubImport from './github-import';
import SelectDiffsForChallenge from './select-diffs-for-challenge'
import MarkdownEditor from './markdown-editor';
import InterviewTimer from './interview-timer';

const widgetComponents = {
  'GithubImport': GithubImport,
  'SelectDiffsForChallenge': SelectDiffsForChallenge,
  'MarkdownEditor': MarkdownEditor,
  'InterviewTimer': InterviewTimer
};

const widgetRoots = new Map();

export const unmountReactWidgets = () => {
  widgetRoots.forEach((root, element) => {
    root.unmount();
    widgetRoots.delete(element);
  });
}

export const mountReactWidgets = () => {
  document.querySelectorAll('[data-react-widget]').forEach(element => {
    const widgetName = element.getAttribute('data-react-widget');
    if (!widgetName) throw new Error('data-react-widget attribute is required');

    const Component = widgetComponents[widgetName];
    if (!Component) {
      console.error(`No component found for widget "${widgetName}"`);
      return;
    }

    if (!widgetRoots.has(element)) {
      const root = createRoot(element);
      widgetRoots.set(element, root);
    }

    const root = widgetRoots.get(element);

    root.render(
      <React.StrictMode>
        <Component />
      </React.StrictMode>
    );
  });
}


