import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { targetText: String };

  copyToClipboardFallback(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
      const successful = document.execCommand('copy');
      const msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }
    document.body.removeChild(textArea);
  }

  async copyToClipboard(e) {
    const text = this.targetTextValue;

    try {
      if (!navigator.clipboard || !navigator.clipboard.writeText) throw "Clipboard API not available";

      await navigator.clipboard.writeText(text)
      console.log("Text copied to clipboard");
    } catch (error) {
      this.copyToClipboardFallback(text);
      console.error("Failed to copy text to clipboard:", error);
    }

    e.target.innerText = "check_circle"

  }
}
