import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "input", "form", "confirm"];
  static values = { expected: String };

  connect() {
    this.modalElement = this.element.closest('#deletePromptModal[data-controller="modal"]');
  }

  openModal() {
    this.modalElement.dispatchEvent(new CustomEvent("openModal"));
  }

  closeModal() {
    this.modalElement.dispatchEvent(new CustomEvent("closeModal"));
  }

  confirmAndSubmit() {
    if (this.inputTarget.value === this.expectedValue) {
      this.formTarget.submit();
    }
  }

  checkInput() {
    const inputValue = this.inputTarget.value;

    if (inputValue === this.expectedValue) {
      this.confirmTarget.removeAttribute("disabled");
    } else {
      this.confirmTarget.setAttribute("disabled", "disabled");
    }
  }
}
