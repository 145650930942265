import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="aspects"
export default class extends Controller {
    connect() {
        console.log("connected");
        super.connect();
        window.addEventListener('keydown', this.handleKeyPress);

    }

    disconnect() {
        window.removeEventListener('keydown', this.handleKeyPress);
        super.disconnect();
    }

    handleKeyPress(event) {
        if (event.key === 'ArrowRight') {
            console.log("next");
        }

        if (event.key === 'ArrowLeft') {
            console.log("prev");
        }
    }
}
