import {
  mountReactWidgets,
  unmountReactWidgets,
} from "../widgets/_mount-react-widget";
import * as Turbo from "@hotwired/turbo";
import "../controllers";

Turbo.start();

console.info("DOMContentLoaded via Vite ⚡");

// Mount React widgets
document.addEventListener("turbo:load", mountReactWidgets);

document.addEventListener("turbo:frame-render", () => {
  unmountReactWidgets();
  mountReactWidgets();
});

// Unmount React widgets
document.addEventListener("turbo:before-cache", () => {
  unmountReactWidgets();
});

