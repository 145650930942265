import { Controller } from "@hotwired/stimulus";

const composeMatchUrl = (baseUrl, matchA, matchB) => {
  const base = new URL(baseUrl);
  base.searchParams.append(matchA.type + `_id`, matchA.id);
  base.searchParams.append(matchB.type + `_id`, matchB.id);

  return base.href;
};

export default class extends Controller {
  static targets = ["annotationMatcher", "commentMatcher"];
  static values = {
    matchUrl: String,
  };

  currentMatch = null;

  connect() {
    console.log("connected aspect-building", this.commentMatcherTargets);
  }

  cancel() {
    delete this.currentMatch;
  }

  match({ params: { type, id } }) {
    if (!this.currentMatch) {
      // step 1: initiate the match
      console.log("Starting Match Stack, waiting for pairing", { type, id });
      this.currentMatch = { type, id };
    } else {
      // step 2: complete the match

      if (this.currentMatch.id === id) {
        alert(`Can't match with itself`);
        this.cancel();
        return;
      }

      if (this.currentMatch.type === type) {
        alert(`Can't match a type of ${type} with the same type`);
        this.cancel();
        return;
      }

      console.log("Processing Match Pairing");
      const matchB = { type, id };

      const url = composeMatchUrl(
        this.matchUrlValue,
        this.currentMatch,
        matchB,
      );

      Turbo.visit(url, { frame: "modal" });
    }
  }

  disconnect() {
    console.log("*** disconnect aspect-building");
  }
}
