import { Controller } from "@hotwired/stimulus";

function animateCloseDialog(dialogElement) {
  dialogElement.classList.add("close");
  dialogElement.addEventListener(
    "animationend",
    () => {
      dialogElement.classList.remove("close");
      dialogElement.close();
    },
    { once: true }
  );
}

export default class extends Controller {
  static targets = ["modal", "input", "form", "confirm"];
  static values = { expected: String };

  connect() {
    // Listen for the custom event to open the modal
    this.element.addEventListener("openModal", this.openModal.bind(this));
    this.element.addEventListener("closeModal", this.closeModal.bind(this));
  }

  openModal(event) {
    event.preventDefault();
    const modalElement = document.querySelector(
      `#${event.currentTarget.id} dialog`
    );
    modalElement.showModal();
  }

  closeModal(event) {
    event.preventDefault();
    animateCloseDialog(this.modalTarget);
  }

  confirmAndSubmit() {
    if (this.inputTarget.value === this.expectedValue) {
      this.formTarget.submit();
    }
  }

  checkInput() {
    const inputValue = this.inputTarget.value;

    if (inputValue === this.expectedValue) {
      this.confirmTarget.removeAttribute("disabled");
    } else {
      this.confirmTarget.setAttribute("disabled", "disabled");
    }
  }
}
