import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="diff-search"
export default class extends Controller {
  static targets = ["input"];

  debounceSearch(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      const actionUrl = new URL(window.location.href);
      const form = this.element.closest("form");
      const formData = new FormData(form);

      // If diffs_page is different than 1, reset to first page when search
      if (formData.get("diffs_page") !== "1") {
        formData.set("diffs_page", 1);
      }

      formData.forEach((value, key) => {
        actionUrl.searchParams.set(key, value);
      });

      const newState = { path: actionUrl.href, reload: true };
      history.replaceState(newState, "", actionUrl.href);

      const turboFrame = document.getElementById("diffs");
      if (turboFrame) {
        turboFrame.setAttribute("src", actionUrl.href);
      }
    }, 300);
  }
}
