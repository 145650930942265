import React, { useRef } from 'react';
import { components } from '@octokit/openapi-types'

type Repository = components["schemas"]["repository"];
type PullRequest = components["schemas"]["pull-request"];

const GithubImport = () => {
  const modalRef = useRef<HTMLDialogElement>(null);

  const [loading, setLoading] = React.useState<{ repos: boolean, prs: boolean, preview: boolean }>({ repos: true, prs: true, preview: true })

  const [repos, setRepos] = React.useState<Repository[]>([]);
  const [selectedRepo, setSelectedRepo] = React.useState<Repository | null>(null);

  const [pullrequests, setPullrequests] = React.useState<PullRequest[]>([]);
  const [selectedPR, setSelectedPR] = React.useState<PullRequest | null>(null);

  const [errors, setErrors] = React.useState<string[]>([]);

  const [preview, setPreview] = React.useState<string>("");

  const showVisibility: React.MouseEventHandler<HTMLButtonElement> = async (e) => {
    e.preventDefault()

    if (!modalRef.current) return

    modalRef.current.showModal(); // Open the dialog
    modalRef.current.focus();


    await fetchRepos()
  }

  const hideVisibility: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (e) e.preventDefault()

    if (!modalRef.current) return

    setRepos([])
    setSelectedRepo(null)
    setPullrequests([])
    setSelectedPR(null)
    setPreview("")
    setErrors([])
    setLoading({ repos: true, prs: true, preview: true })

    modalRef.current.close()
  }

  const fetchRepos = async () => {
    try {
      setLoading(loading => ({ ...loading, repos: true }))

      const res = await fetch('/users/fetch_repos', { method: 'GET', headers: { 'Content-Type': 'application/json' } })

      const data = await res.json()
      setRepos(data)

    } catch (error) {
      setErrors([...errors, error])
    } finally {
      setLoading(loading => ({ ...loading, repos: false }))
    }
  }

  const fetchPullRequests = async (selectedRepoName: string) => {
    if (!selectedRepoName) return

    try {
      setLoading(loading => ({ ...loading, prs: true }))

      const res = await fetch(`/users/fetch_prs?name=${selectedRepoName}`, { method: 'GET', headers: { 'Content-Type': 'application/json' } })

      const data = await res.json()
      setPullrequests(data)
    } catch (error) {
      setErrors([...errors, error])
    } finally {
      setLoading(loading => ({ ...loading, prs: false }))
    }
  }

  const selectRepo = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedFullName = event.target.value;
    const selectedRepository = repos.find(repo => repo.full_name === selectedFullName) || null;
    setSelectedRepo(selectedRepository);

    if (selectedRepository) {
      fetchPullRequests(selectedRepository.full_name);
    }
  };

  const selectPR = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = event.target.value

    const pr = pullrequests.find(pr => pr.id === parseInt(selected)) || null;

    setSelectedPR(pr);

    if (pr) {
      await fetchAndSetPreview(pr);
    }
  }

  const fetchAndSetPreview = async (pr: PullRequest) => {
    const repo = repos.find(repo => repo.full_name === pr.head.repo?.full_name)
    const prNumber = pr.number

    if (!repo || !prNumber) return

    try {
      setPreview("")
      setLoading(loading => ({ ...loading, preview: true }))

      const res = await fetch(`/users/fetch_content_of_pr?repo_id=${repo.id}&pr_number=${prNumber}`, { method: 'GET', headers: { 'Content-Type': 'text/plain' } })
      const data = await res.text()

      if (data !== "") {
        setPreview(data)
      }

    } catch (error) {
      setErrors([...errors, error])
    } finally {
      setLoading(loading => ({ ...loading, preview: false }))
    }
  }

  const importPRPatchFile = async () => {
    const title = `[Imported] ${selectedPR?.title.slice(0, 20)}`
    title.length > 20 ? title + "..." : title

    const params = {
      title: `[Imported] ${title}`,
      pr_title: selectedPR?.title,
      description: selectedPR?.body,
      source: preview,
    }

    const csrfToken = document.querySelector('[name=csrf-token]')?.content

    try {
      const res = await fetch('/diffs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
        },
        body: JSON.stringify(params)
      })


      if (res.status === 200) {
        hideVisibility(null)
        window.location.reload()
      }

    } catch (error) {
      setErrors([...errors, error])
    }
  }

  return (
    <div>
      <dialog ref={modalRef} className="modal">
        <div className="import__wrapper">
          <h3>Import from Github</h3>
          <p>Create a Diff Template from a Github PR.</p>

          {errors.map((error, index) => <p key={index}>{error}</p>)}

          <div className="import__form">
            <div className="import__form-group">

              {loading.repos ? (
                <div>Loading Repos...</div>
              ) : (
                <div>
                  <label htmlFor="select_repos" className="form__label">
                    Select a repository
                  </label>
                  <select
                    name="repos"
                    id="select_repos"
                    value={selectedRepo ? selectedRepo.full_name : '0'}
                    onChange={selectRepo}
                    className="form__input"
                    disabled={repos.length === 0}
                  >
                    <option value="0" disabled>Select a repository</option>
                    {repos.map((repo, index) => (
                      <option key={index} value={repo.full_name}>{repo.full_name}</option>
                    ))}
                  </select>
                </div>
              )}


              {selectedRepo?.full_name && (
                <div>
                  <label htmlFor="select_prs" className="form__label">
                    Select a pull request:
                  </label>
                  <select
                    name="prs"
                    id="select_prs"
                    value={selectedPR ? selectedPR.id : '0'}
                    onChange={selectPR}
                    className="form__input" defaultValue="0"
                    disabled={pullrequests.length === 0}
                  >
                    <option value="0" disabled>Select a PR</option>
                    {pullrequests.map((pr, index) => (
                      <option key={index} value={pr.id}>{pr.title}</option>
                    ))}
                  </select>
                </div>
              )}

            </div>

            {selectedPR && loading.preview ? (
              <div>Loading Preview</div>
            ) : (
              preview !== "" && <textarea className="import__preview" readOnly disabled={preview === ""} defaultValue={preview}></textarea>
            )}
          </div>

          <div className="modal__actions">
            <button role="button" onClick={hideVisibility} className="btn btn--outline btn--small">Cancel</button>

            <button role="button" onClick={importPRPatchFile} className="btn btn--primary">Create new Diff from PR</button>
          </div>
        </div>
      </dialog>
      <button role="button" onClick={showVisibility} className="btn btn--primary">Import from Github</button>
    </div>
  );
};

export default GithubImport;
