import React from 'react';

export const MdBoldIcon = () => (
  <svg fill="#000000" width="16px" height="16px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
    <text id="Bold" x="9.879px" y="56.132px" style={{ fontFamily: "'Roboto-Bold', 'Roboto'", fontWeight: 700, fontSize: '67.597px' }}>B</text>
  </svg>
);

export const MdEmIcon = () => (
  <svg fill="#000000" width="16px" height="16px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
    <text id="Italic" x="23.228px" y="56.103px" style={{ fontFamily: "'Roboto-Italic', 'Roboto'", fontStyle: "italic", fontSize: '66.631px' }}>i</text>
  </svg>
)

export const MdCodeIcon = () => (
  <svg fill="#000000" width="16px" height="16px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
    <path id="codeblock" d="M38.147,56.256c3.68,-0.818 6.451,-2.165 8.312,-4.039c1.861,-1.874 2.791,-4.35 2.791,-7.428l0,-4.886c0,-3.978 2.204,-5.967 6.612,-5.967l0,-3.56c-4.408,0 -6.612,-1.981 -6.612,-5.942l0,-5.108c-0.041,-2.995 -0.972,-5.414 -2.791,-7.256c-1.82,-1.841 -4.59,-3.179 -8.312,-4.014l-1.186,2.824c4.346,1.096 6.519,3.953 6.519,8.569l0,4.96c0,3.716 1.736,6.302 5.209,7.759c-3.473,1.474 -5.209,4.084 -5.209,7.833l0,5.058c-0.062,4.502 -2.235,7.293 -6.519,8.373l1.186,2.824Zm-12.44,-0.025c-3.679,-0.818 -6.448,-2.164 -8.308,-4.037c-1.86,-1.873 -2.79,-4.348 -2.79,-7.424l0,-4.884c0,-3.975 -2.203,-5.963 -6.609,-5.963l0,-3.559c4.406,0 6.609,-1.979 6.609,-5.939l0,-5.105c0.041,-2.994 0.971,-5.411 2.79,-7.252c1.818,-1.84 4.587,-3.178 8.308,-4.012l1.184,2.822c-4.343,1.096 -6.515,3.951 -6.515,8.565l0,4.958c0,3.714 -1.735,6.299 -5.206,7.755c3.471,1.472 5.206,4.082 5.206,7.829l0,5.055c0.062,4.5 2.234,7.289 6.515,8.369l-1.184,2.822Z" style={{ fillRule: "nonzero" }} />
  </svg>
)

export const MdListIcon = () => (
  <svg fill="#000000" width="16px" height="16px" viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}>
    <path d="M12.016,12.164c-2.204,0 -3.983,1.778 -3.983,3.983c0,2.203 1.779,3.982 3.983,3.982c2.204,0 3.982,-1.779 3.982,-3.982c0,-2.205 -1.778,-3.983 -3.982,-3.983Z" style={{ fillRule: "nonzero" }} />
    <path d="M12.016,28.094c-2.204,0 -3.983,1.779 -3.983,3.983c0,2.203 1.779,3.982 3.983,3.982c2.204,0 3.982,-1.779 3.982,-3.982c0,-2.204 -1.778,-3.983 -3.982,-3.983Z" style={{ fillRule: "nonzero" }} />
    <path d="M12.016,44.024c-2.217,0 -3.983,1.792 -3.983,3.983c0,2.19 1.792,3.982 3.983,3.982c2.19,0 3.982,-1.792 3.982,-3.982c0,-2.191 -1.765,-3.983 -3.982,-3.983Z" style={{ fillRule: "nonzero" }} />
    <rect x="23.981" y="45.848" width="32.083" height="4.023" style={{ fillRule: "nonzero" }} />
    <rect x="23.981" y="13.997" width="32.083" height="3.984" style={{ fillRule: "nonzero" }} />
    <rect x="23.981" y="29.915" width="32.083" height="4" style={{ fillRule: "nonzero" }} />
  </svg>

)
