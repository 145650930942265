import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
    static targets = ["select"];

    applySort(event) {
        const actionUrl = new URL(window.location.href);
        const form = this.element.closest("form");
        const formData = new FormData(form);

        formData.forEach((value, key) => {
            actionUrl.searchParams.set(key, value);
        });

        const sortSelect = form.querySelector("select[name='sort_by']");
        if (sortSelect) {
            const sortValue = sortSelect.value;
            actionUrl.searchParams.set("sort_by", sortValue);
        }

        const newState = { path: actionUrl.href, reload: true };
        history.replaceState(newState, "", actionUrl.href);

        const turboFrame = document.getElementById("diffs");
        if (turboFrame) {
            turboFrame.setAttribute("src", actionUrl.href);
        }
    }
}
