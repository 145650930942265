import React, { useRef, useEffect, useState } from 'react';

const InterviewTimer = () => {
  const [currentTimeInSeconds, setCurrentTimeInSeconds] = useState<number>(0);
  const [timerActive, setTimerActive] = useState<boolean>(false);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const toggleTimer = () => {
    if (timerActive) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      };

      setTimerActive(false);
    } else {
      intervalRef.current = window.setInterval(() => {
        setCurrentTimeInSeconds((prevTime) => prevTime + 1);
      }, 1000);

      setTimerActive(true);
    }
  };

  const resetTimer = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    };

    setCurrentTimeInSeconds(0);
    setTimerActive(false);
  };

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;

    if (hours > 0) {
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  };

  return (
    <div className="timer__wrapper">
      <div className="timer__control">
        <button onClick={resetTimer}>
          <span className="material-symbols-outlined">restart_alt</span>
        </button>
        <p>{formatTime(currentTimeInSeconds)}</p>
      </div>
      <div className="timer__control">
        <button onClick={toggleTimer}>
          {timerActive ? <span className="material-symbols-outlined">pause_circle</span> : <span className="material-symbols-outlined">play_circle</span>}
        </button>
      </div>
    </div>
  );
};

export default InterviewTimer;
