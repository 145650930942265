import React, { useEffect, useState } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const MarkdownRenderer = ({ propsData: unparsedText }: { propsData: string }) => {
  const [markdownPreview, setMarkdownPreview] = useState<string>('');

  useEffect(() => {
    const sanitizedMarkdown = DOMPurify.sanitize(marked(unparsedText, { breaks: true }));
    setMarkdownPreview(sanitizedMarkdown);
  }, [unparsedText])


  return (
    <div className="markdown-preview" dangerouslySetInnerHTML={{ __html: markdownPreview }} />
  )
}

export default MarkdownRenderer;
