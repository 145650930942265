// app/javascript/controllers/pagy_pagination_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.querySelectorAll("a").forEach((button) => {
      button.addEventListener("click", this.loadPage.bind(this));
    });
  }

  loadPage(event) {
    const url = new URL(event.currentTarget.href);

    const newState = { path: url.href, reload: true };
    history.replaceState(newState, "", url.href);

    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Use smooth scrolling
    });
  }

  handleChange(event) {
    event.preventDefault();
    const form = event.target.form;
    const actionUrl = new URL(form.getAttribute("action"));
    const formData = new FormData(form);
    const queryParams = new URLSearchParams(formData);

    const allowedParams = ["query", "sort_by"];
    allowedParams.forEach((name) => {
      const value = actionUrl.searchParams.get(name);
      if (value) {
        queryParams.append(name, value);
      }
    });

    const path = `${actionUrl.pathname}?${queryParams}`;
    const newState = { path, reload: true };
    history.pushState(newState, "", path);

    const diffsTurboFrame = document.getElementById("diffs");
    const searchTurboFrame = document.getElementById("diffs_search");
    const sortTurboFrame = document.getElementById("diffs_sort");

    if (diffsTurboFrame) {
      diffsTurboFrame.setAttribute("src", path);
      searchTurboFrame.setAttribute("src", path);
      sortTurboFrame.setAttribute("src", path);
    }
  }
}
