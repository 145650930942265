import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";
import Sortable from "sortablejs";

const composeMatchUrl = (baseUrl, matchA, matchB) => {
  const base = new URL(baseUrl);
  base.searchParams.append(matchA.type + `_id`, matchA.id);
  base.searchParams.append(matchB.type + `_id`, matchB.id);
  return base.href;
};

export default class extends Controller {
  static targets = [
    "destination",
    "annotationMatcher",
    "commentMatcher",
    "diffs",
  ];

  static values = {
    matchUrl: String,
  };

  set currentMatch(value) {
    this.updateUI(value);
    this._currentMatch = value;
  }

  get currentMatch() {
    return this._currentMatch;
  }

  connect() {
    this.initializeSortable();
    this._currentMatch = null;
  }

  initializeSortable() {
    Sortable.create(this.destinationTarget, {
      ...this.sortableDefaultSettings,
      group: {
        name: "topics",
        put: false,
      },
      sort: true,
      onEnd: this.onTopicSortEnd,
    });
  }

  onTopicSortEnd(evt, _originalEvent) {
    document.body.style.cursor = "unset";

    const url = evt.item.dataset.updateUrl;
    const newPosition = evt.newIndex + 1;
    const csrfToken = document.querySelector("[name=csrf-token]")?.content;

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken,
      },
      body: JSON.stringify({
        new_position: newPosition,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((data) => {
        const tag = evt.to.dataset.frameTag;
        const turboFrame = document.getElementById(tag);
        turboFrame.innerHTML = data;
      })
      .catch((_error) => {
        console.error("Error updating content:", _error);
      });
  }

  sortableDefaultSettings() {
    return {
      animation: 150,
      filter: ".ignore-elements",
      easing: "cubic-bezier(1, 0, 0, 1)",
      forceFallback: true,
      fallbackClass: "dragged-item",
      emptyInsertThreshold: 0,
      onStart: (_evt) => {
        document.body.style.cssText += "cursor: grabbing !important;";
      },
    };
  }

  match({ params: { type, id } }) {
    if (!this.currentMatch) {
      console.log("Starting Match Stack, waiting for pairing", { type, id });
      this.currentMatch = { type, id };
    } else {
      this.completeMatch(type, id);
      this.cancelMatch();
    }
  }

  completeMatch(type, id) {
    if (this.currentMatch.id === id && this.currentMatch.type === type) {
      alert(`Can't match with itself`);
      return;
    }

    if (this.currentMatch.type === type) {
      alert(`Can't match a type of ${type} with the same type`);
      return;
    }

    console.log("Processing Match Pairing");
    const matchB = { type, id };
    const url = composeMatchUrl(this.matchUrlValue, this.currentMatch, matchB);
    Turbo.visit(url, { frame: "modal" });
  }

  cancelMatch() {
    console.log("Cancelling Match Stack");
    this.currentMatch = null;
  }

  updateUI(newCurrentMatchValue) {
    const { type, id } = newCurrentMatchValue || this._currentMatch || {};

    const infoTarget = this.getInfoTarget(type, id);
    const [matchButtons, missButtons, opportunityButtons] =
      this.getButtonGroups(type);

    const actionButtons = [
      ...matchButtons,
      ...missButtons,
      ...opportunityButtons,
    ];

    if (newCurrentMatchValue) {
      this.showElement(infoTarget);
      this.hideElements(actionButtons);
      this.diffsTarget.classList.add("match-pairing");
    } else {
      this.hideElement(infoTarget);
      this.showElements(actionButtons);
      this.diffsTarget.classList.remove("match-pairing");
    }
  }

  getInfoTarget(type, id) {
    return type === "annotation"
      ? this.diffsTarget.querySelector(`#match_info_annotation_${id}`)
      : this.diffsTarget.querySelector(`#match_info_comment_${id}`);
  }

  getButtonGroups(type) {
    const matchButtons = this.diffsTarget.querySelectorAll(
      `.aspect-button--match[data-topics-creation-type-param="${type}"]`
    );
    const missButtons = this.diffsTarget.querySelectorAll(
      `.aspect-button--opportunity`
    );
    const opportunityButtons =
      this.diffsTarget.querySelectorAll(`.aspect-button--miss`);
    return [matchButtons, missButtons, opportunityButtons];
  }

  showElement(element) {
    if (element) {
      element.style.display = "block";
    }
  }

  hideElement(element) {
    if (element) {
      element.style.display = "none";
    }
  }

  hideElements(elements) {
    elements.forEach((element) => this.hideElement(element));
  }

  showElements(elements) {
    elements.forEach((element) => this.showElement(element));
  }

  jumpToNote(event) {
    const aspectType = event.currentTarget.dataset.aspectType;
    const noteId = event.currentTarget.dataset.noteId;
    const reviewId = event.currentTarget.dataset.reviewId;

    /* Open the review tab so we can scroll to element */
    const tab = document.querySelector(`[data-tab-id="review_${reviewId}"]`);
    tab.click();

    const noteType = aspectType === "miss" ? "annotation" : "comment";
    const note = document.getElementById(`${noteType}_${noteId}`);
    note?.scrollIntoView({ block: "start" });
  }
}
