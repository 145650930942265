import { Application } from "@hotwired/stimulus";

const application = Application.start();

// Configure Stimulus development experience
application.debug = false;
window.Stimulus = application;

export { application };

(function registerViewComponentControllers() {
  console.log("Register View Component Controllers");
  const controllers = import.meta.glob(
    "../../views/components/**/controller.js",
    { eager: true },
  );

  for (let path in controllers) {
    let module = controllers[path];

    let name = path
      .match(/views\/components\/(.+)\/controller\.js$/)[1]
      .replaceAll("_", "-")
      .replaceAll("/", "--");

    console.log(`-- Register '${name}'`);
    application.register(name, module.default);
  }
})();

window.addEventListener("popstate", function (event) {
  // Check if the state object exists and contains the information
  // that indicates a full page reload should be triggered
  if (event.state && event.state.reload) {
    window.location.reload();
  }
});
