import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initialize()
    document.querySelector(".thumbnail--current").scrollIntoView({ behavior: "smooth", inline: "center" })

    const clickableElements = document.querySelectorAll('.topic__slide-card-header');

    if (clickableElements.length > 0) {
      clickableElements.forEach((clickableElement) => {
        clickableElement.addEventListener('click', (event) => {
          const targetElement = event.currentTarget;
          const targetValue = targetElement.getAttribute('data-scroll-target');
          const destinationElement = document.querySelector(`.line[title="${targetValue}"]`);

          if (destinationElement) {
            destinationElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            destinationElement.classList.add('highlight');
            setTimeout(() => {
              destinationElement.classList.remove('highlight');
            }, 3000);
          } else {
            console.log('No element found with the matching title');
          }
        });
      });
    } else {
      console.log('Clickable elements not found');
    }
  }
}
