import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ["tab", "tablink"];

  openTab(event) {
    const tabName = event.currentTarget.dataset.tab;

    this.tabTargets.forEach((tab) => {
      tab.style.display = "none";
    });

    this.tablinkTargets.forEach((tablink) => {
      tablink.classList.remove("active-tab");
    });

    document.getElementById(tabName).style.display = "flex";

    event.currentTarget.classList.add("active-tab");
  }
}
