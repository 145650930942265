import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="modal-helper"
export default class extends Controller {
  static values = { modalId: String };

  openModal() {
    const element = this.getModalElement(this.modalIdValue);
    element.dispatchEvent(new CustomEvent("openModal"));
  }

  closeModal() {
    const element = this.getModalElement(this.modalIdValue);
    element.dispatchEvent(new CustomEvent("closeModal"));
  }

  getModalElement(id) {
    return this.element.querySelector(`[data-controller="modal"]`);
  }
}
