// app/javascript/controllers/tabs_controller.js
import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ['active']
  static targets = ["tab", "panel"]
  static values = {
    initialTab: String
  }

  connect() {
    this.hidePanels()

    if (this.initialTabValue) {
      this.activateTab(this.initialTabValue)
    }
  }

  select(event) {
    const givenTab = event.currentTarget
    this.activateTab(givenTab.dataset.tabId)
  }

  activateTab(id) {
    const tab = this.findTab(id)
    const panel = this.findPanel(id)

    if (!panel.hidden) {
      return;
    }

    this.hideAll()

    panel.hidden = false
    tab.classList.add(...this.activeClasses)
  }

  // private
  findTab(id) {
    return this.tabTargets.find(element => element.dataset.tabId === id)
  }

  findPanel(id) {
    return this.panelTargets.find(element => element.dataset.tabId === id)
  }

  hideAll() {
    this.hideTabs()
    this.hidePanels()
  }

  hidePanels() {
    this.panelTargets.map(x => x.hidden = true) // hide all tabs
  }

  hideTabs() {
    this.tabTargets.map(x => x.classList.remove(...this.activeClasses)) // deactive all btns
  }
}
