import React, { useRef, useEffect, useState } from 'react';
import MarkdownRenderer from './markdown-renderer';
import { MdBoldIcon, MdEmIcon, MdCodeIcon, MdListIcon } from './icons';

const formatMarkers = {
  bold: '**',
  italic: '_',
  code: '`',
  list: '-'
}

const MarkdownEditor = () => {
  const [textareaValue, setTextareaValue] = useState<string>('');
  const [previewMode, setPreviewMode] = useState<boolean>(false);

  const originalTextAreaElement = document.getElementById('annotation_body') as HTMLTextAreaElement | null;

  useEffect(() => {
    if (originalTextAreaElement) {
      setTextareaValue(originalTextAreaElement.value);
    }
  }, [])

  const handleChange = (value: string) => {
    setTextareaValue(value)
    originalTextAreaElement!.value = value
  }

  const changeFormat = (formatMarker: string) => {
    const textarea = document.getElementById('editor') as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textareaValue.substring(start, end);

    let newText = '';

    if (formatMarker === formatMarkers.list) {
      const formattedText = selectedText.split('\n').map(line => `- ${line}`).join('\n');
      newText = `${textarea.value.substring(0, start)}${formattedText}${textarea.value.substring(end)}`;

    } else {
      newText = `${textareaValue.substring(0, start)}${formatMarker}${selectedText}${formatMarker}${textareaValue.substring(end)}`;
    }

    handleChange(newText)

    textarea.focus();
    textarea.setSelectionRange(start + formatMarker.length, end + formatMarker.length);
  }

  const handleFormat = (e: React.MouseEvent<HTMLButtonElement>, formatName: string) => {
    e.preventDefault();

    const formatMarker = formatMarkers[formatName];
    changeFormat(formatMarker)
  };

  return (
    <>
      <div className="markdown-tabnav markdown-header">
        <div
          role="tab"
          className={`markdown-tabnav__button ${previewMode ? '' : 'markdown-tabnav__button--active'}`}
          onClick={() => setPreviewMode(false)}
        >
          Write
        </div>
        <div
          role="tab"
          className={`markdown-tabnav__button ${previewMode ? 'markdown-tabnav__button--active' : ''}`}
          onClick={() => setPreviewMode(true)}>
          Preview
        </div>

        {!previewMode && (
          <div className="markdown-tools">
            <button onClick={(e) => handleFormat(e, 'bold')} className="markdown-tools__button"><MdBoldIcon /></button>
            <button onClick={(e) => handleFormat(e, 'italic')} className="markdown-tools__button"><MdEmIcon /></button>
            <button onClick={(e) => handleFormat(e, 'code')} className="markdown-tools__button"><MdCodeIcon /></button>
            <button onClick={(e) => handleFormat(e, 'list')} className="markdown-tools__button"><MdListIcon /></button>
          </div>
        )}
      </div>
      <div className="markdown-view">
        {previewMode ?
          (
            <MarkdownRenderer propsData={textareaValue} />
          )
          :
          (
            <div className="markdown-editor">
              <textarea
                value={textareaValue}
                onChange={(e) => handleChange(e.currentTarget.value)}
                className="form__input"
                id="editor"
                rows={10}></textarea>
            </div>)
        }
      </div>
    </>
  )
}

export default MarkdownEditor;
