import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["comment"];
  connect() {
    this.observer = new MutationObserver(this.scrollIntoView);
    this.observer.observe(document.body, { childList: true, subtree: true });

    this.scrollIntoView();
  }

  disconnect() {
    this.observer.disconnect();
  }

  scrollIntoView = () => {
    const element = this.commentTargets[0];
    if (!element) return;

    const bodyRect = document.body.getBoundingClientRect(),
      elemRect = element.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top;

    window.scrollTo({
      top: offset - (100 + 20), //sticky header height + line height
      behavior: "smooth",
    });
  };
}
