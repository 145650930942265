import {Controller} from "@hotwired/stimulus"


// Connects to data-controller="scroll"
export default class extends Controller {
    onScroll = () => {
        if (window.scrollY !== 0) {
            this.element.classList.add("scroll--active")
        } else {
            this.element.classList.remove("scroll--active")
        }
    }

    connect() {
        this.onScroll();
        window.addEventListener('scroll', this.onScroll);
    }

    disconnect() {
        window.removeEventListener('scroll', this.onScroll);
        super.disconnect();
    }
}
