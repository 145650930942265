import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.open();
  }

  // hide modal on successful form submission
  submitEnd(e) {
    if (e.detail.success) {
      this.close();
    }
  }

  open() {
    this.element.showModal();
  }

  close() {
    this.element.close();

    const frame = document.getElementById("modal");
    frame.removeAttribute("src");
    frame.innerHTML = "";
  }
}
