import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["question", "answer"]

  connect() {
    this.questionTargets.forEach(question => {
      question.addEventListener("click", this.toggle.bind(this));
    });
  }

  toggle(event) {
    const question = event.currentTarget;
    const active = this.questionTargets.find(q => q.classList.contains("active"));

    if (active && active !== question) {
      active.classList.toggle("active");
      active.nextElementSibling.style.maxHeight = 0;
    }

    question.classList.toggle("active");
    const answer = question.nextElementSibling;
    
    if (question.classList.contains("active")) {
      answer.style.maxHeight = answer.scrollHeight + "px";
    } else {
      answer.style.maxHeight = 0;
    }
  }
}

